define("pilots/services/session-account", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-side-effects */
  /* eslint-disable ember/require-return-from-computed */
  /* eslint-disable ember/no-classic-classes */
  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    pusherData: Ember.inject.service(),
    // eslint-disable-next-line ember/use-brace-expansion
    account: Ember.computed('session.data.authenticated.token', 'session.isAuthenticated', function () {
      if (this.get('session.isAuthenticated') || this.get('session.data.authenticated.token')) {
        const promise = this.get('store').findRecord('pilot', 'stubId');
        promise.then(response => {
          if (_environment.default.environment !== 'test') {
            // Assign additional information to session.data.authenticated so chameleon can access
            this.set('session.data.authenticated.id', response.id);
            this.set('session.data.authenticated.fullName', `${response.first_name} ${response.last_name}`);
            this.set('session.data.authenticated.first_name', response.first_name);
            this.set('session.data.authenticated.last_name', response.last_name);
            return this.get('pusherData').start(response.id);
          }
        }, error_response => {
          console.error('Error fetching pilot record:', error_response);
          this.session.set('isAuthenticated', false);
        });
        return promise;
      }
    }),
    headers: Ember.computed('session.data.authenticated.{accessToken,idToken,token,email}', 'session.isAuthenticated', function () {
      let headers = {};
      let {
        accessToken,
        idToken,
        token,
        email
      } = this.get('session.data.authenticated');
      if (this.session.isAuthenticated) {
        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`;
          headers['X-OAuth-User-ID-Token'] = idToken;
        } else {
          headers['Authorization'] = `Token token="${token}", email="${email}"`;
        }
      } else {
        this.session.invalidate();
      }
      return headers;
    }),
    getAuthTokens: function (urlParams) {
      const tokens = {
        email: urlParams.get('email') || this.session.data.authenticated.email,
        token: urlParams.get('token') || this.session.data.authenticated.token,
        idToken: urlParams.get('auth0IDToken') || this.session.data.authenticated.idToken,
        accessToken: urlParams.get('auth0Token') || this.session.data.authenticated.accessToken
      };
      tokens.email = tokens.email.replace(' ', '+');
      if (Ember.isPresent(tokens.accessToken)) {
        tokens.password = `bearerToken:${tokens.accessToken}`;
      } else if (Ember.isPresent(tokens.token)) {
        tokens.password = `token:${tokens.token}`;
      }
      return tokens;
    },
    setAuth0Tokens: function (tokens) {
      if (Ember.isPresent(tokens.accessToken) && Ember.isPresent(tokens.idToken)) {
        const idTokenPayload = this.parseJwt(tokens.idToken);
        this.set('session.data.authenticated.accessToken', tokens.accessToken);
        this.set('session.data.authenticated.idToken', tokens.idToken);
        this.set('session.data.authenticated.idTokenPayload', idTokenPayload);
        this.set('session.data.authenticated.email', tokens.email);
      }
      if (Ember.isPresent(tokens.token) && Ember.isPresent(tokens.email)) {
        this.set('session.data.authenticated.token', tokens.token);
        this.set('session.data.authenticated.email', tokens.email);
      }
    },
    parseJwt: function (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
      return JSON.parse(jsonPayload);
    }
  });
  _exports.default = _default;
});