define("pilots/templates/components/pilot-profile-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5VOfNzXL",
    "block": "[[[10,0],[14,0,\"section-header\"],[12],[1,\"\\n  DOCUMENTATION\\n\"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"model\",\"pilotLicense\",\"index\",\"destroyLicense\"],[[33,4],[30,1],[28,[37,5],[[30,2],0],null],[28,[37,6],[[30,0],\"destroyLicense\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,7]],null]],null],null,[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"model\",\"pilotLicense\",\"index\",\"destroyLicense\"],[[33,4],[30,3],[28,[37,5],[[33,8],[28,[37,5],[[30,4],0],null]],null],[28,[37,6],[[30,0],\"destroyLicense\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"\\n\"],[41,[33,10],[[[1,\"  \"],[1,[28,[35,11],null,[[\"model\",\"createLicense\",\"missingInfo\",\"isModalOpen\",\"openModal\"],[[33,4],[28,[37,6],[[30,0],\"newLicense\"],null],[33,12],[33,13],[28,[37,6],[[30,0],\"newLicense\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,14],null,[[\"isModalOpen\",\"model\",\"pilot\",\"availableLicenses\",\"closeModal\",\"addLicense\"],[[33,13],[33,4],[33,4,[\"pilot\"]],[33,15],[28,[37,6],[[30,0],\"closeModal\"],null],[28,[37,6],[[30,0],\"createLicense\"],null]]]]],[1,\"\\n\"]],[\"pilotLicense\",\"index\",\"pilotLicense\",\"index\"],false,[\"each\",\"-track-array\",\"flightCertificates\",\"pilot-profile-license\",\"model\",\"addition\",\"action\",\"otherLicenses\",\"flightCertificatesCount\",\"if\",\"licensesAvailable\",\"pilot-profile-license-new\",\"missingInfo\",\"isModalOpen\",\"pilot-license-modal\",\"availableLicenses\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-licenses.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});