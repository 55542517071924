define("pilots/app", ["exports", "jquery", "ember-resolver", "ember-load-initializers", "pilots/config/environment", "@chamaeleonidae/chmln"], function (_exports, _jquery, _emberResolver, _emberLoadInitializers, _environment, _chmln) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _jquery.default.ajaxSetup({
    headers: {
      'X-API-TOKEN': _environment.default.api.app_token
    }
  });
  if (_environment.default.chameleonProjectKey) {
    _chmln.default.init(_environment.default.chameleonProjectKey, {
      fastUrl: "https://fast.chameleon.io/"
    });
  }

  // eslint-disable-next-line ember/no-classic-classes
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});